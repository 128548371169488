import ServiceImage from '../assets/img/services.svg';

export const servicesData = {
    image: ServiceImage,
    title: 'Let\'s create more tools and ideas that brings us together',
    subtitle: 'Our team of experts offers various services to help your business succeed. From consulting to on-demand customization, we have the skills and experience to create a tailored solution that meets your company\'s unique needs. Whether you\'re looking to improve efficiency, increase sales, or boost your online presence, we\'re here to help. Contact us today to learn more about our services and how we can help your business thrive.',
    services: [
        {
            title: 'Consulting',
            subtitle: 'We provide tailored solutions to meet your business needs. Our team offers various IT consulting services to optimize your infrastructure, increase efficiency, and enhance security. Contact us for a customized solution that fits your business needs.',
            href: '/#/contact'
        },
        {
            title: 'On-demand Customization',
            subtitle: 'We deliver flexible solutions for your business needs. Our team provides customized IT solutions that adapt to your unique and changing business requirements. Contact us for software development, integration, cloud solutions, and cybersecurity services.',
            href: '/#/contact'
        }
    ]
};
